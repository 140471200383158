<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="400px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" v-bind="attrs" v-on="on">
          {{ buttonText || $t("common.confirm") }}
        </v-btn>
      </template>

      <v-card>
        <v-card-title>
          <span class="headline">{{ title }}</span>
        </v-card-title>

        <v-card-text>
          {{ $t("common.verify") }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="cancel()">
            {{ $t("common.cancel") }}
          </v-btn>
          <v-btn color="blue darken-1" text @click="confirm()">
            {{ $t("common.yes") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "DeleteDialog",

  data() {
    return { dialog: false };
  },

  props: {
    title: {
      type: String,
      required: true,
    },

    buttonText: {
      type: String,
      default: null,
    },
  },

  methods: {
    confirm() {
      this.$emit("confirm");
      this.dialog = false;
    },

    cancel() {
      this.$emit("cancel");
      this.dialog = false;
    },
  },
};
</script>
