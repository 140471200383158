<template>
  <v-container>
    <v-tabs>
      <v-tab v-if="permitted('Actions.Update')">
        {{ $t("actions.details.edit") }}
      </v-tab>

      <v-tab>
        {{ $t("actions.details.cooldowns") }}
      </v-tab>

      <v-tab-item>
        <action-form v-model="action" edit />
      </v-tab-item>

      <v-tab-item>
        <v-container v-if="action.cooldownOnAction">
          <div v-if="!action.isOnCooldown">
            <h3>{{ $t("actions.noCooldownDescription") }}</h3>
          </div>
          <div v-else>
            <h3>{{ $t("actions.resetCooldownActionDescription") }}</h3>
            <v-btn @click="resetCooldown(action)" color="primary">{{
              $t("actions.resetCooldown")
            }}</v-btn>
          </div>
        </v-container>
        <v-container v-else>
          <v-row class="ma-4" v-if="hasAnyCooldowns">
            <confirm-dialog
              :title="$t('actions.resetAllCooldown')"
              :buttonText="$t('actions.resetAllCooldown')"
              @confirm="resetCooldown(action)"
            />
          </v-row>

          <v-data-table
            :headers="[
              {
                text: $t('actions.fields.isOnCooldown'),
                value: 'isOnCooldown',
              },
              { text: $t('actions.fields.identifier'), value: 'identifier' },
              { text: '', value: 'actions' },
            ]"
            :items="resources"
            :items-per-page="10"
            class="elevation-1"
          >
            <template v-slot:item.isOnCooldown="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    :color="item.isOnCooldown ? 'warning' : 'success'"
                    dark
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{
                      item.isOnCooldown
                        ? "mdi-sort-clock-ascending"
                        : "mdi-check"
                    }}
                  </v-icon>
                </template>
                <span>{{
                  item.isOnCooldown
                    ? $t("actions.onCooldown")
                    : $t("actions.noCooldownActive")
                }}</span>
              </v-tooltip>
            </template>

            <template v-slot:item.actions="{ item }">
              <v-btn
                @click="resetCooldownResource(item)"
                color="primary"
                v-if="item.isOnCooldown"
                >{{ $t("actions.resetCooldown") }}</v-btn
              >
            </template>
          </v-data-table>
        </v-container>
      </v-tab-item>
    </v-tabs>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import ActionForm from "@/components/action/ActionForm";
import ConfirmDialog from "@/components/common/ConfirmDialog";

export default {
  name: "ActionDetail",

  components: {
    ActionForm,
    ConfirmDialog,
  },

  data() {
    return {
      action: undefined,
    };
  },

  computed: {
    resources() {
      return this.action.resources
        .slice(0, this.action.resources.length)
        .sort((a, b) => b.isOnCooldown - a.isOnCooldown);
    },

    hasAnyCooldowns() {
      if (this.action.isOnCooldown) return true;

      return this.resources.some((r) => r.isOnCooldown);
    },

    ...mapGetters("actions", ["getActionById"]),
  },

  methods: {
    async loadAction() {
      this.action = this.getActionById(this.$route.params.id);
      if (this.action == undefined) {
        this.$store.dispatch(
          "alert/error",
          this.$t("actions.errors.noActionIdFound")
        );
        this.$router.push("/actions");
        return;
      }
    },

    resetCooldownResource(item) {
      // reset the cooldown for the selected resource
      this.$store.dispatch(
        "actions/resetResourceCooldown",
        item.actionResourceId
      );
      item.isOnCooldown = false;
    },

    resetCooldown(item) {
      // reset the cooldown for the action
      this.$store.dispatch("actions/resetCooldown", item.actionId);
      this.action.isOnCooldown = false;

      for (var r of this.action.resources) r.isOnCooldown = false;
    },
  },

  async created() {
    await this.loadAction();
  },
};
</script>
